import React from 'react';
import RenderImage from './RenderImage';
import Main from './../view/Main';
import './MainSection.css'

import useViewport from '../hook/useViewPort';

const MainSection = () => {
    const { width } = useViewport();

    return (
        <div className='upper-div' >
            <div className='main-section'>
                <Main />
            </div>
        </div >
    );
}

export default MainSection;