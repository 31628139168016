import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from './../hook/useWeb3'
import ContractABI from './../utils/abi/abiSmart.json';
import { toast } from "react-toastify";
import './Main.css'
import { CONTRACT_ADDRESS } from '../config/index';
import AuthModal from './components/authModal';


const Main = () => {
    const { account } = useWeb3React()
    const [loading, setLoading] = useState(false)
    const [totalMinted, setTotalMinted] = useState(0)
    const webThree = useWeb3();
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [isWhiteListed, setIsWhiteListed] = useState(false)
    const [isEnabledWhiteListed, setIsEnabledWhiteListed] = useState(false)
    const [isEnabledPublic, setIsEnabledPublicSale] = useState(false)

    const [isSucceed, setIsSucced] = useState(false)

    const getContract = async () => {
        const contract = new webThree.eth.Contract(ContractABI, CONTRACT_ADDRESS);
        return contract
    };

    const getTotalSupply = async () => {
        const contract = await getContract()
        try {
            let res = await contract.methods.totalSupply().call()
            setTotalMinted(res)
            setIsSucced(false)
        } catch (error) {
            console.log(error);
            setIsSucced(false)
        }

    }

    const checkSale = async () => {
        setLoading(true)
        const contract = await getContract()
        let isEnableWhiteListMint = await contract.methods.whitelistMintEnabled().call()
        let isEnablePublicMint = await contract.methods.pausedPublicsale().call()
        setIsEnabledWhiteListed(isEnableWhiteListMint)
        setIsEnabledPublicSale(isEnablePublicMint)
        setLoading(false)
    }
    useEffect(() => {
        getTotalSupply()
        checkSale()
        checkUserWhiteListed()
    }, [account])

    useEffect(() => {
        isSucceed && getTotalSupply()
    }, [isSucceed])

    const fetchTotalSupply = async (value) => {
        let check = false
        const contract = await getContract()
        if(+totalMinted < 5000){
            if(!isEnabledWhiteListed){
                check = false
                toast.info('White list minting is not enabled yet')
            }else{
                let maxWhiteListMintUser = await contract.methods.maxMintWhitelist().call()
                let userWhiteListMintCount = await contract.methods.userWhitelistMintCount(account).call({ from: account })
                if (+userWhiteListMintCount < maxWhiteListMintUser) {
                    check = true
                } else {
                    check = false
                    toast.info(`you already minted ${maxWhiteListMintUser} , and limit is ${maxWhiteListMintUser} per wallet`)
                }

            }
        }else{
            if(!isEnabledPublic){
                check = false
                toast.info('Public sale minting is not enabled yet')
            }else{
                let maxPublicMintUser = await contract.methods.maxMintPublicsale().call()
                let userPublicMintCount = await contract.methods.userPublicMintCount(account).call({ from: account })
                if (+userPublicMintCount < maxPublicMintUser) {
                    check = true
                } else {
                    check = false
                    toast.info(`you already minted ${userPublicMintCount} , and limit is ${userPublicMintCount} per wallet`)
                }

            }
        }
        return check;
    }

    const checkUserWhiteListed = async () => {
        setLoading(true)
        const contract = await getContract()
        let _isWhiteListed = await contract.methods.isWhitelisted(account).call({ from: account })
        setIsWhiteListed(_isWhiteListed)
        setLoading(false)
    }

    const mint = async () => {
        setLoading(true)
        const valid = await fetchTotalSupply()
        if (valid) {
            toast.info('Minting Start')
            const mintContractInstance = await getContract()
            try {
                if (+totalMinted < 5000) {
                    await mintContractInstance.methods.whitelistMint(1).send({ from: account, value: 0, gas: 200000 })
                } else if (+totalMinted < 5205) {
                    await mintContractInstance.methods.publicSaleMint(1).send({ from: account, value: 0, gas: 200000 })
                } else {
                    toast.info('All NFTs are minted')
                }
                setIsSucced(true)
                toast.info('Token minted Successfully')
                setLoading(false)
            } catch (error) {
                console.log('error', error);
                setLoading(false)
                if (error.code === 4001) {
                    toast.error('User Reject transaction')
                }

            }
        } else {
            setLoading(false)
        }
    }


    return (
        <div className='main' id="main">
            <div className='main-heading sale' >FUCKLIST SALE</div>
            <div className='input-box-xp '>
                <p className='input-field-text'>Quantity :</p>
                <p className='input-field-text ml-4'>1</p>
            </div>
            <div className='input-box-xp '>
                <p className='input-field-text'>Price :</p>
                <p className='input-field-text ml-4'>Fucking Free!</p>
            </div>
            <div className='mt-4'>
                {account ?
                    +totalMinted >= 5205 ?
                        <button className='btn-set'>
                            Sold Out
                        </button> :
                        <button className={!loading && !isWhiteListed ? 'not-fuckListed' : 'btn-set'} onClick={() => {
                            if (!loading && isWhiteListed ) {
                                mint()
                            }
                        }}>
                            {loading ? "Loading..." : isWhiteListed ? "Mint Now" : "Not Fucklisted"}
                        </button> :
                    <button className='btn-set' onClick={() => { setShowAuthModal(true) }}>
                        CONNECT
                    </button>
                }

                {+totalMinted >= 5205 ?
                    <p className='main-p '>5555 / 5555 </p>
                    : +totalMinted >= 5000 ?
                        <p className='main-p '>{totalMinted} / 5205</p>
                        : <p className='main-p '>{totalMinted} / 5000</p>
                }
            </div>
            <AuthModal
                show={showAuthModal}
                handleClose={() => setShowAuthModal(false)}
            />
        </div>
    );
}

export default Main;