import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import walletConnectSvg from './../../images/walletC.svg'
import metamaskIcon from './../../images/metamask.png'

import './auth.css'
import useAuth from '../../hook/useAuth'
import { SUPPORTED_WALLETS } from './../../config/index'
import { toast } from 'react-toastify'
import useViewport from '../../hook/useViewPort'

const AuthModal = ({
    show,
    handleClose,
}) => {
    const { login } = useAuth()
    const { width } = useViewport()
    const IsMobile = width <= 990 // check for mobile screen

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>
                        Connect Wallet

                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4 d-flex align-items-center">
                <div className='wallet-wrapper'>
                    {IsMobile &&
                        <>
                            <p>Tap the button below to <span>Open MetaMask</span>. Please access this site on MetaMask's in-app browser for a seamless experience.</p>
                        </>
                    }
                    <div className='wallet-box'>
                        <img className='image-wallet' onClick={async () => {

                            await login(SUPPORTED_WALLETS.METAMASK.connector, SUPPORTED_WALLETS.METAMASK.name)
                            toast.info('Wallet Connected')
                            handleClose()

                        }} src={metamaskIcon} alt="" />
                        <img className='image-wallet' onClick={async () => {
                            await login(SUPPORTED_WALLETS.WALLET_CONNECT.connector, SUPPORTED_WALLETS.WALLET_CONNECT.name)
                            toast.info('Wallet Connected')
                            handleClose()
                        }} src={walletConnectSvg} alt="" />
                    </div>
                    {IsMobile &&
                        <div className='wallet-col'>
                            <a href='https://metamask.app.link/dapp' target={'_blank'} ><button>Open Metamask Apk</button></a>
                        </div>}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AuthModal
